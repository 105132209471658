<script setup lang="ts">
import { StandardProps } from "./Standard.props"

withDefaults(defineProps<StandardProps>(), {
  fullscreen: false,
  showBackIcon: false
})

const { notifications, removeNotification } = useNotification()

const standardModalHeader = ref()
const standardModalFooter = ref()

const { height: headerHeight } = useElementBounding(standardModalHeader)
const { height: footerHeight } = useElementBounding(standardModalFooter)

defineEmits<{ (e: "onClose"): void; (e: "back"): void }>()

const headerFooterHeight = computed(
  () => `${headerHeight.value + footerHeight.value}px`
)

const isAModalOpened = computed(() =>
  Object.values(useState("useModal__modals").value).some(
    (modalState) => modalState
  )
)
</script>

<template>
  <div
    class="
      standard-modal-wrapper
      relative
      overflow-hidden
      bg-white
      shadow-01
      md:mx-4
      md:rounded-lg
    "
    :class="
      fullscreen ? 'flex flex-col justify-between md:h-fit' : 'mx-4 rounded-lg'
    "
  >
    <div>
      <div
        ref="standardModalHeader"
        class="
          standard-modal-header
          flex
          items-center
          justify-between
          gap-2
          bg-slate-800
          px-4
          py-3
          text-white
          md:px-6
        "
      >
        <div class="flex items-center">
          <IconsArrowLeft
            v-if="showBackIcon"
            class="mr-4 h-6 w-6 cursor-pointer text-white"
            @click="$emit('back')"
          />
          <p v-if="title" class="beaver-bold md:dolphin-bold">
            {{ title }}
          </p>
        </div>

        <UtilsIcon
          name="Close.svg"
          color="white"
          @click="$emit('onClose')"
          class="h-6 w-6 cursor-pointer"
        />
      </div>

      <div
        v-if="isAModalOpened"
        class="
          wrapper-notifications
          padded
          absolute
          top-14
          z-[999999]
          flex
          w-full
          flex-col
          gap-2
          md:gap-4
          lg:px-[71px]
        "
      >
        <TransitionGroup name="opacity">
          <NotificationAlert
            class="flex justify-end"
            v-for="notification in notifications"
            :key="notification.id"
            v-bind="notification"
            @on-close="removeNotification(notification.id)"
          />
        </TransitionGroup>
      </div>

      <div
        class="modal-content relative overflow-auto"
        :class="[
          fullscreen
            ? 'custom-max-height-fullscreen'
            : 'custom-max-height rounded-lg'
        ]"
      >
        <slot name="content" />
      </div>
    </div>

    <div
      class="modal-cta sticky bottom-0 left-0 right-0"
      ref="standardModalFooter"
    >
      <slot name="cta" />
    </div>
  </div>
</template>

<style lang="scss" scoped>

.custom-max-height-fullscreen {
  max-height: calc(85vh - v-bind(headerFooterHeight));
  max-height: calc(100dvh - v-bind(headerFooterHeight));

  @screen md {
    max-height: calc(90vh - v-bind(headerFooterHeight));
    max-height: calc(90dvh - v-bind(headerFooterHeight));
  }
}
.custom-max-height {
  max-height: calc(80vh - v-bind(headerFooterHeight));
  max-height: calc(90dvh - v-bind(headerFooterHeight));

  @screen md {
    max-height: calc(90vh - v-bind(headerFooterHeight));
    max-height: calc(90dvh - v-bind(headerFooterHeight));
  }
}

</style>
